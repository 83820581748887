<template>
  <div class="mx-4 pb-36">
    <div class="flex flex-col">
      <div class="flex items-center mb-2">
        <p class="text-left text-xl font-bold text-onboarding_gray">{{ selectedRate !== null ? 'Editar' : 'Crear'}} tarifa</p>
        <div class="flex items-center ml-2 justify-center bg-yellow_ribbon rounded-full h-6 w-6">
          <i class="pi pi-star" style="font-size: 12px"></i>
        </div>
      </div>
    </div>

    <div class="mt-4">
      <p class="uppercase text-left text-2xs mb-2">Descripción</p>
      <div style="color: #6E7E8C">
        <textarea class="flex flex-col border rounded-md w-full p-2" rows="5" v-model="rateDescription" placeholder="Pack Historias + Reel + YouTube + Tiktok + Google" maxlength="200" />
        <p class="text-right text-2xs mt-1">{{ rateDescription?.length + '/200'}}</p>
      </div>
    </div>

    <div class="mt-2">
      <p class="uppercase text-left text-2xs mb-2">Precio</p>
      <div>
        <input type="number" v-model="rateValue" placeholder="Base imponible">
        <div class="flex justify-between">
          <p class="text-2xs mt-1 text-onboarding_light_gray">Tú recibes: <span class="text-onboarding_gray">{{ $filters.currencyFormatter((rateValue * 0.9)) + '€' }}</span></p>
          <p class="text-2xs mt-1 text-onboarding_light_gray">(IVA Exc.)</p>
        </div>
        <div class="flex justify-between">

          <p class="text-2xs mt-1 text-onboarding_light_gray">Fee de gestión: <span class="text-onboarding_gray">{{ $filters.currencyFormatter((rateValue * 0.1)) + '€' }}</span></p>
        </div>
      </div>
    </div>

    <div class="flex items-center text-left mt-4 ">
      <input id="accept" type="checkbox" class="self-start inline-block" v-model="confirmTerms">
      <label class="inline-block ml-1 text-sm" for="accept">He leído y entendido que en el momento de retirar el saldo de Solofoodies se requiere presentar factura.</label>
    </div>
  </div>
  <div class="fixed px-4 max-w-5xl mx-auto bottom-20 inset-x-0">
    <button
      :disabled="!confirmTerms || !rateDescription || !rateValue || isLoading"
      class="bg-primary text-white w-full rounded-full py-sm overflow-hidden disabled:bg-gray"
      @click="selectedRate ? update() : create()"
    >
      <!--          <span v-if="loading.createCollab">-->
      <!--            <a-loader class="h-6 w-6 block mx-auto" :color="'text-white'"/>-->
      <!--          </span>-->
      <span v-if="isLoading">
            <a-loader class="h-6 w-6 block mx-auto" :color="'text-white'"/>
          </span>
      <span v-else class="block h-6 mx-auto">
            Guardar
          </span>
    </button>
  </div>
  <a-alert
      hide-cancel
      :show="showModal"
      @confirm="$emit('goPrev')"
      :confirm-text="'Aceptar'"
  >
    <div class="flex">
      <p class="inline-block align-middle">{{ `¡Se ha ${isCreating ? 'creado' : 'actualizado'} la tarifa con éxito!`}}</p>
    </div>
  </a-alert>
  <a-alert
      hide-cancel
      :show="showErrorModal"
      @confirm="$emit('goPrev')"
      :confirm-text="'Aceptar'"
  >
    <div class="flex flex-col">
      <p class="align-middle text-red">{{ `¡Ha ocurrido un error!`}}</p>
      <p class="mt-2 align-middle">{{ `No se ha podido ${isCreating ? 'crear' : 'actualizar'} la tarifa` }}</p>
    </div>
  </a-alert>
</template>

<script>
import { onMounted, onUnmounted, ref } from 'vue'
import { useRepository, useSession } from '@/hooks'

export default {
  name: 'step-create',
  props: {
    selectedRate: { required: false, type: Object, default: null },
    isCreating: { required: false, type: Boolean, default: false },
    showDelete: { required: false, type: Boolean, default: false }
  },
  emits: ['update:showDelete', 'goPrev'],
  setup (props, { emit }) {
    const showDeleteButton = ref(props.showDelete)
    const rateDescription = ref('')
    const rateValue = ref()
    const confirmTerms = ref(false)
    const isLoading = ref(false)
    const showModal = ref(false)
    const showErrorModal = ref(false)
    // const modalTitle = ref('')
    const { user } = useSession()
    const foodieRepository = useRepository(({ foodies }) => foodies)

    const create = () => {
      isLoading.value = true

      const body = {
        name: 'Nombre del producto',
        description: rateDescription.value,
        price_e2: rateValue.value * 100
      }
      foodieRepository.products(user?.foodie?.id).store(body)
        .then(() => {
          showModal.value = true
        })
        .catch(() => (showErrorModal.value = true))
        .finally(() => (isLoading.value = false))
    }

    const update = () => {
      isLoading.value = true
      const body = {
        description: rateDescription.value,
        price_e2: rateValue.value * 100
      }

      foodieRepository.products(user?.foodie?.id)
        .update(props.selectedRate.id, body)
        .then(() => {
          showModal.value = true
        })
        .catch(() => (showErrorModal.value = true))
        .finally(() => (isLoading.value = false))
    }

    onMounted(() => {
      emit('update:showDelete', true)

      if (props.selectedRate !== null) {
        rateDescription.value = props.selectedRate.description
        rateValue.value = props.selectedRate.price_e2 / 100
      } else {
        rateDescription.value = ''
        rateValue.value = null
      }
    })
    onUnmounted(() => {
      emit('update:showDelete', false)
    })

    return {
      showDeleteButton,
      rateDescription,
      rateValue,
      confirmTerms,
      isLoading,
      showModal,
      showErrorModal,
      create,
      update
    }
  }
}
</script>

<style scoped>

</style>
