<template>
    <a-stepper :slots="3" class="h-screen flex flex-col pt-16 pb-36">
      <template #header="{ index, goPrev }">
        <div class="p-4 h-14 z-40 block my-auto fixed top-0 bg-white max-w-5xl mx-auto inset-x-0 lg:top-14 text-left">
          <div class="flex" :class="showDeleteButton ? 'flex justify-between' : ''">
            <div @click="prev(index, goPrev)" class="cursor-pointer">
              <i class="icon-arrowLeft inline-block"></i>
              <h3 class="uppercase inline-block font-bold">atrás</h3>
            </div>
            <div v-if="showDeleteButton">
              <h3 class="uppercase inline-block font-bold text-SFRed cursor-pointer" @click="handleCancel(goPrev)">{{ !selectedRate ? 'Cancelar' : 'Eliminar' }}</h3>
            </div>
          </div>
        </div>

      </template>

      <template #step-1="{ goNext }">
        <step-information
          @goNext="goNext"
        />
      </template>
      <template #step-2="{ goNext }">
        <step-list
          v-model:haveDelete="haveDelete"
          @createRate="() => goCreate(goNext)"
          @goUpdate="(value) => getRate(value, goNext)"
          @goDelete="(value) => getDelete(value, goNext)"
        />
      </template>
      <template #step-3="{ goPrev }">
        <step-create
            v-model:showDelete="showDeleteButton"
            :selectedRate="selectedRate"
            :isCreating="isCreating"
            @goPrev="goPrev"
        />
      </template>
    </a-stepper>
    <a-alert
        hide-cancel
        :show="deleteModal"
        @confirm="deleteRate"
        @cancel="deleteModal = false"
        :confirm-text="'Aceptar'"
        :cancel-text="'Cancelar'"
        :title="'Eliminar'"
    >
      <div class="flex">
        <p class="inline-block align-middle text-red">¿Seguro que desea eliminar este producto?</p>
      </div>
    </a-alert>
</template>

<script>
// TODO Arreglar informacion del foodie
// import Step1 from './step-1'
import { ref } from 'vue'
import StepInformation from './step-information'
import StepList from './step-list'
import StepCreate from './step-create'
import { useRouter } from 'vue-router'
import { useRepository, useSession } from '../../../../hooks'
// import Router from 'vue-router'

export default {
  name: 'index',
  components: {
    StepInformation,
    StepList,
    StepCreate
  },
  setup (props, Router) {
    const showDeleteButton = ref(false)
    const selectedRate = ref(null)
    const isCreating = ref(false)
    const router = useRouter()
    const deleteModal = ref(false)
    const haveDelete = ref(false)
    const { user } = useSession()
    const repository = useRepository(({ foodies }) => foodies)

    // asdf
    const handleCancel = (goPrev) => {
      if (!selectedRate.value) {
        goPrev()
      } else {
        deleteModal.value = true
      }
    }

    const getRate = (rate, goNext) => {
      selectedRate.value = rate
      goNext()
    }

    const getDelete = (rate) => {
      selectedRate.value = rate
      deleteModal.value = true
    }

    const deleteRate = () => {
      repository.products(user.foodie.id)
        .delete(selectedRate.value.id)
        .then(() => {
          deleteModal.value = false
        })
        .finally(() => {
          router.go()
        })
    }

    const goCreate = (goNext) => {
      isCreating.value = true
      selectedRate.value = null
      goNext()
    }

    const prev = (index, goPrev) => {
      switch (index) {
        case 2: {
          router.push({ path: '/explore' })
          break
        }
        case 3: {
          goPrev()
          break
        }
        default: {
          if (isCreating.value) {
            goPrev()
          } else {
            router.push({ path: '/explore' })
          }
          break
        }
      }
    }

    return {
      showDeleteButton,
      selectedRate,
      isCreating,
      deleteModal,
      haveDelete,
      getRate,
      goCreate,
      getDelete,
      deleteRate,
      prev,
      handleCancel
    }
  }
}
</script>

<style scoped>

</style>
