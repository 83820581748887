<template>
  <div class="mx-4 pb-36">
    <div class="flex items-center justify-center">
      <div class="flex items-center border rounded-md shadow-md py-2 px-6">
        <div>
          <a-initials :name="'Alex Gomez'" :src="user.profile_picture" :desing-class="'custom-class'"/>
<!--          <div class="flex items-center ml-auto justify-center bg-yellow_ribbon rounded-full h-6 w-6">-->
<!--            <i class="pi pi-star" style="font-size: 12px"></i>-->
<!--          </div>-->
        </div>
        <div class="flex-auto ml-2">
          <div class="flex items-center">
            <p class="text-md text-black">{{ user?.name }}</p>
          </div>
          <div class="flex items-center">
            <p>{{ '@' + user.ig_username }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="grid grid-col gap-4 text-justify mt-4 px-2">
      <p>Ahora podrás crear tarifas.</p>
      <p>En cuanto hayas completado una colaboración, en un plazo máximo de 10 días, verás reflejado en tu balance de Solofoodies el importe acordado.</p>
      <p>Para retirar tus fondos debes contar con un balance mayor a 50 EUR y presentar una factura siguiendo las instrucciones enviadas por el equipo de Solofoodies.</p>
      <p>Tu pago será procesado en un plazo máximo de 14 días desde la recepción de la factura.</p>
      <p>La tarifa por honorarios de gestión de Solofoodies es de 10%</p>
    </div>
    <div class="flex items-center justify-center" style="padding-top: 20px;">
      <input id="accept" type="checkbox" class="inline-block cursor-pointer" v-model="confirmTerms">
      <label class="inline-block ml-1 cursor-pointer text-sm" for="accept">He leído, entiendo y acepto los términos y condiciones.</label>
    </div>
    <div class="flex items-center justify-center p-2" style="padding-bottom: 20px;">
      <input id="accept2" type="checkbox" class="inline-block cursor-pointer" v-model="confirmTerms2">
      <label class="inline-block ml-1 cursor-pointer text-sm" for="accept2">Confirmo que cuento con la posibilidad de facturar por mis servicios ofrecidos según la ley española</label>
    </div>
  </div>
  <div class="fixed px-4 max-w-5xl mx-auto bottom-20 inset-x-0">
    <button
      :disabled="(!confirmTerms || !confirmTerms2) || loading"
      class="bg-primary text-white w-full rounded-full py-sm overflow-hidden disabled:bg-gray"
      @click="markAccept"
    >
      <span v-if="loading">
        <a-loader class="h-6 w-6 block mx-auto" :color="'text-white'"/>
      </span>
      <span v-else>Aceptar</span>
    </button>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useSession, useRepository } from '@/hooks'

export default {
  name: 'step-information',
  emits: ['goNext'],
  setup (props, { emit }) {
    const confirmTerms = ref(false)
    const confirmTerms2 = ref(false)
    const store = useStore()
    const loading = ref(false)
    const repository = useRepository(({ foodies }) => foodies)
    const { user } = useSession()

    const markAccept = () => {
      localStorage.setItem('acceptPremium', 'true')
      loading.value = true

      repository.products(store.state.foodies?.current?.id)
        .acceptTerms()
        .then(() => {
          loading.value = false
          emit('goNext')
        })
        .catch(() => (loading.value = false))
    }

    onMounted(() => {
      const hasMarkedTerms = store.state.foodies?.current?.premium_terms

      if (hasMarkedTerms) {
        emit('goNext')
      }
    })
    return {
      confirmTerms,
      confirmTerms2,
      user,
      loading,
      markAccept
    }
  }
}
</script>

<style scoped>
.custom-class {
  width: 72px;
  height: 72px;
  border: 2px solid #F7D708;
  z-index: 10;
}
</style>
