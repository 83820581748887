<template>
  <div class="flex flex-col rounded-md border mb-2">
    <div class="flex p-2">
      <p class="text-left grow" style="flex-grow: 1">{{ rate.description }}</p>
      <p class="flex-none font-bold ml-4">{{ '€' + $filters.currencyFormatter(rate.price_e2 / 100) }}</p>
    </div>

    <div class="flex justify-end p-2">
      <a class="text-primary mr-2 cursor-pointer" @click="$emit('goUpdate')">Editar</a>
      <a class="text-SFRed cursor-pointer" @click="$emit('goDelete')">Eliminar</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'rateCard',
  props: {
    rate: { required: false, type: Object }
  },
  emit: ['goUpdate', 'goDelete'],
  setup () {
    return {}
  }
}
</script>

<style scoped>

</style>
