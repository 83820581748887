<template>
  <div class="flex flex-col rounded-md" style="box-shadow: 0 0 3px #cecece; padding: 30px 0">
    <p class="text-onboarding_light_gray">No tienes una tarifa aún</p>
    <p class="text-primary cursor-pointer" @click="$emit('createRate')">+ Crear nueva tarifa</p>
  </div>
</template>

<script>

export default {
  name: 'defaultCard',
  emits: ['createRate']
}
</script>

<style scoped>

</style>
