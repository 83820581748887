<template>
  <div class="mx-4 pb-36">
    <div v-if="isLoading" class="flex flex-col h-6 w-6 block mx-auto">
      <a-loader :color="'text-onboarding_light_gray'"></a-loader>
    </div>
    <div v-else class="flex flex-col">
      <div class="flex items-center mb-2">
        <p class="text-left text-xl font-bold text-onboarding_gray">Mis tarifas</p>
        <div class="flex items-center ml-2 justify-center bg-yellow_ribbon rounded-full h-6 w-6">
          <i class="pi pi-star" style="font-size: 12px"></i>
        </div>
      </div>

      <div v-if="rates.length > 0">
        <div v-for="rate in rates" :key="rate">
          <rate-card :rate="rate" @goUpdate="$emit('goUpdate', rate)" @goDelete="$emit('goDelete', rate)"/>
        </div>
      </div>
      <div v-else>
        <default-card @createRate="$emit('createRate')"/>
      </div>

      <div class="flex mt-4 items-center">
        <h2 class="flex-none text-justify text-xl font-bold text-onboarding_gray">Cómo funciona</h2>
        <button @click="hideText = !hideText" class="flex items-center">
          <i class="flex-none ml-2 pi transitions-all" :class="hideText ? 'pi-angle-up' : 'pi-angle-down'"></i>
        </button>
      </div>
      <transition name="fade">
        <div v-if="hideText" class="grid gap-5 text-justify mt-2 transition-transform duration-3000 overflow-hidden">
          <p>Crea tarifas para ofrecer a restaurantes, delivery y marcas. Las colaboraciones premium incluyen un 100% de descuento para ti y tu acompañante.</p>
          <p>Recibirás el pago en un plazo máximo de 10 días en tu cuenta Solofoodies. Cualquier demora o incumplimiento resultará en un reembolso completo al cliente.</p>
          <p>La tarifa por honorarios de gestión de Solofoodies es de 10%</p>
        </div>
      </transition>
    </div>
  </div>
  <div class="fixed px-4 max-w-5xl mx-auto bottom-20 inset-x-0">
    <button
      class="bg-primary text-white w-full rounded-full py-sm overflow-hidden disabled:bg-gray"
      @click="$emit('createRate')"
      :disabled="isLoading"
    >
      <!--          <span v-if="loading.createCollab">-->
      <!--            <a-loader class="h-6 w-6 block mx-auto" :color="'text-white'"/>-->
      <!--          </span>-->
      <span>+ Crear nueva tarifa</span>
    </button>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import rateCard from './components/rateCard'
import DefaultCard from './components/defaultCard'
import { useRepository, useSession } from '@/hooks'

export default {
  name: 'step-list',
  components: {
    DefaultCard,
    rateCard
  },
  props: {
    haveDelete: { required: false, type: Boolean }
  },
  emits: ['update:showDelete', 'createRate', 'goUpdate', 'goDelete'],
  setup (props, { emit }) {
    // const rates = ref([{ id: 1, rate: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s,', price: 300 },
    //   { id: 2, rate: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s,', price: 400 },
    //   { id: 3, rate: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s,', price: 500 }])
    const rates = ref([])
    const { user } = useSession()
    const repository = useRepository(({ foodies }) => foodies)
    const isLoading = ref(false)
    const hideText = ref(false)

    const getProducts = () => {
      repository.products(user?.foodie?.id)
        .index()
        .then(({ data }) => {
          rates.value = data.data
        })
        .catch(() => {
          rates.value = []
        })
        .finally(() => {
          isLoading.value = false
        })
    }

    onMounted(() => {
      isLoading.value = true

      if (props.haveDelete) {
        getProducts()
      }
      getProducts()
    })

    return {
      rates,
      isLoading,
      hideText
    }
  }
}
</script>

<style scoped>
/*.slide-fade-enter-active {*/
/*  transition: all 0.3s ease-out;*/
/*}*/

/*.slide-fade-leave-active {*/
/*  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);*/
/*}*/

/*.slide-fade-enter-from,*/
/*.slide-fade-leave-to {*/
/*  transform: translateX(20px);*/
/*  opacity: 0;*/
/*}*/
.fade-enter-active, .fade-leave-active {
  transition:  opacity 0.3s ease;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>
